<template>
    <div>
        <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col cols="12" :md="headToHeadExpanded ? 12 : 6">
                <!-- HEAD TO HEAD PIE -->
                <stats-card v-if="showAverage" @secondTitleActive="showAverage = false" title="Pie" secondTitle="Scatter" @expand="toggleHeadToHeadExpanded" :defaultOpenOptions="true">
                    <template slot="headerActions">
                    <v-row>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                    :items="getTeamsHurlingSort"
                                    v-model="headToHeadAway"
                                    @change="updateHeadToHeadResults"
                                    label="Team 1"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                    :items="headToHeadPieChartTeamOption"
                                    v-model="headToHeadHome"
                                    @change="updateHeadToHeadResults"
                                    label="Team 2"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                    :items="headToHeadHomeAwayOptions"
                                    v-model="headToHeadFilter"
                                    @change="updateHeadToHeadFilter"
                                    item-text="label"
                                    return-object
                                    label="Venue"
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'" class="d-flex align-center">
                            <div>
                                <v-btn
                                    class="grey"
                                    :class="this.$vuetify.theme.dark ? 'darken-2' : 'lighten-2'"
                                    @click="showHeadToHeadFilters = !showHeadToHeadFilters"
                                    >Filters
                                    <v-icon class="ml-1">{{ showHeadToHeadFilters ? 'mdi-arrow-up-drop-circle-outline' : 'mdi-arrow-down-drop-circle-outline' }}</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <transition name="fade">
                        <v-row v-if="showHeadToHeadFilters" :class="this.$vuetify.breakpoint.xs ? 'settings_height_mobile' : 'settings_height'">
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-combobox
                                        :items="headToHeadYearFromFilters"
                                        v-model="headToHeadYearFromFilter"
                                        @change="headToHeadYearFromFilterChanged"
                                        label="Year from"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-combobox
                                        :items="headToHeadYearToFilters"
                                        v-model="headToHeadYearToFilter"
                                        @change="headToHeadYearToFilterChanged"
                                        label="Year to"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-combobox
                                        :items="getFilteredStadiums"
                                        v-model="headToHeadStadiumFilter"
                                        @change="headToHeadStadiumFilterChanged"
                                        item-text="label"
                                        item-value="value"
                                        label="Stadium"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-combobox
                                        :items="headToHeadStageOptions"
                                        v-model="headToHeadStageFilter"
                                        @change="headToHeadStageFilterChanged"
                                        item-text="label"
                                        item-value="value"
                                        label="Stage"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                        </v-row>
                        </transition>
                </template>
                    <template slot="chart">
                        <div id="head-to-pie-chart">
                            <apexchart
                                type="pie"
                                height="650"
                                :options="headToHeadPieChartOptions"
                                :series="headToHeadPieChartTotals"
                            />
                        </div>
                    </template>
                </stats-card>
                <!-- HEAD TO HEAD SCATTER -->
                <stats-card v-else @titleActive="showAverage = true" title="Pie" secondTitle="Scatter"  @expand="toggleHeadToHeadExpanded">
                    <template slot="headerActions">
                    <v-row>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                    :items="getTeamsHurlingSort"
                                    v-model="headToHeadAway"
                                    @change="updateHeadToHeadResults"
                                    label="Team 1"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                    :items="headToHeadPieChartTeamOption"
                                    v-model="headToHeadHome"
                                    @change="updateHeadToHeadResults"
                                    label="Team 2"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                    :items="headToHeadHomeAwayOptions"
                                    v-model="headToHeadFilter"
                                    @change="updateHeadToHeadFilter"
                                    item-text="label"
                                    return-object
                                    label="Venue"
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'" class="d-flex align-center">
                            <div>
                                <v-btn
                                    class="grey"
                                    :class="this.$vuetify.theme.dark ? 'darken-2' : 'lighten-2'"
                                    @click="showHeadToHeadFilters = !showHeadToHeadFilters"
                                    >Filters
                                    <v-icon class="ml-1">{{ showHeadToHeadFilters ? 'mdi-arrow-up-drop-circle-outline' : 'mdi-arrow-down-drop-circle-outline' }}</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <transition name="fade">
                        <v-row v-if="showHeadToHeadFilters" :class="this.$vuetify.breakpoint.xs ? 'settings_height_mobile' : 'settings_height'">
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-combobox
                                        :items="headToHeadYearFromFilters"
                                        v-model="headToHeadYearFromFilter"
                                        @change="headToHeadYearFromFilterChanged"
                                        label="Year from"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-combobox
                                        :items="headToHeadYearToFilters"
                                        v-model="headToHeadYearToFilter"
                                        @change="headToHeadYearToFilterChanged"
                                        label="Year to"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-combobox
                                        :items="getFilteredStadiums"
                                        v-model="headToHeadStadiumFilter"
                                        @change="headToHeadStadiumFilterChanged"
                                        item-text="label"
                                        item-value="value"
                                        label="Stadium"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-combobox
                                        :items="headToHeadStageOptions"
                                        v-model="headToHeadStageFilter"
                                        @change="headToHeadStageFilterChanged"
                                        item-text="label"
                                        item-value="value"
                                        label="Stage"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                        </v-row>
                        </transition>
                </template>
                    <template slot="chart">
                        <div id="head-to-head-scatter-chart" class="chartBackground">
                            <apexchart
                                type="scatter"
                                :options="headToHeadScatterChartOptions"
                                :series="headToHeadScatterSeries"
                            ></apexchart>
                        </div>
                    </template>
                </stats-card>
            </v-col>
            <v-col cols="12" :md="overallExpanded ? 12 : 6">
                <!-- OVERALL TABLE -->
                <stats-card v-if="showOverall" @secondTitleActive="showOverall = false" title="Table" secondTitle="Scatter" @expand="toggleOverallExpanded" :defaultOpenOptions="true">
                    <template slot="headerActions">
                    <v-row>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                    :items="getTeamsHurlingSort"
                                    v-model="overallTeam"
                                    @change="updateOverallResults"
                                    label="Team"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                    :disabled="!overallTeam"
                                    :items="overallOppositionOption"
                                    v-model="overallOpposition"
                                    @change="updateOverallFilter"
                                    multiple
                                    label="Opposition"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                >
                                <template #selection="{ item }">
                                    <span v-if="overallOpposition.length === 1">{{ item.name }}</span>
                                    <span v-else-if="item.name == overallOpposition[0].name">{{ overallOpposition.length }} teams</span>
                                </template>
                                </v-combobox>
                            </div>
                        </v-col>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                    :items="overallHomeAwayOptions"
                                    v-model="overallHomeFilter"
                                    @change="overallHomeFilterChanged"
                                    item-text="label"
                                    return-object
                                    label="Venue"
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'" class="d-flex align-center">
                            <div>
                                <v-btn
                                    class="grey"
                                    :class="this.$vuetify.theme.dark ? 'darken-2' : 'lighten-2'"
                                    @click="showOverallFilters = !showOverallFilters"
                                    >
                                    {{ showOverallFilters ? 'Filters' : 'Filters' }}
                                    <v-icon class="ml-1">{{ showOverallFilters ? 'mdi-arrow-up-drop-circle-outline' : 'mdi-arrow-down-drop-circle-outline' }}</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                    <transition name="fade">
                        <v-row v-if="showOverallFilters" :class="this.$vuetify.breakpoint.xs ? 'settings_height_mobile' : 'settings_height'">
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-combobox
                                        :items="overallYearFromFilters"
                                        v-model="overallYearFromFilter"
                                        @change="overallYearFromFilterChanged"
                                        label="Year from"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-combobox
                                        :items="overallYearToFilters"
                                        v-model="overallYearToFilter"
                                        @change="overallYearToFilterChanged"
                                        label="Year to"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-combobox
                                        :items="getOverallFilteredStadiums"
                                        @change="overallStadiumFilterChanged"
                                        v-model="overallStadiumFilter"
                                        item-text="label"
                                        item-value="value"
                                        label="Stadium"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>                            
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-combobox
                                        :items="overallStageOptions"
                                        @change="overallStageFilterChanged"
                                        v-model="overallStageFilter"
                                        item-text="label"
                                        item-value="value"
                                        label="Stage"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                        </v-row>
                    </transition>
                </template>
                    <template slot="chart">
                        <div id="overall-table">
                            <div v-if="overallTeam" style="display: flex; justify-content: space-evenly;">
                                <span style="color: green;" class="mr-2">Green is a {{ overallTeam.name }} win</span>
                                <span style="color: red;">Red is a {{ overallTeam.name }} loss</span>
                            </div>
                            <v-data-table
                                :headers="overallTableHeaders"
                                :items="getFilteredOverallHurlingResults"
                                class="elevation-1"
                                mobile-breakpoint="0">

                                <template v-slot:item.result="{item}">
                                    <span :style="calculateResultColour(item)">{{ calculateResultText(item) }}</span>
                                </template>

                                <template v-slot:item.opposition="{item}">
                                    <v-img
                                        :src="item.homeTeam && item.homeTeam.id == overallTeam.id ? `/assets/gaa/teams/icons/${item.awayTeam.code}.WEBP` 
                                        : `/assets/gaa/teams/icons/${item.homeTeam.code}.WEBP`"
                                        contain
                                        max-width="30"
                                        class="img_border"
                                        ></v-img>
                                    {{ item.homeTeam && item.homeTeam.id == overallTeam.id ? item.awayTeam.name : item.homeTeam.name }}
                                </template>

                                <template v-slot:item.date="{item}">
                                    {{ `${item.date[2]}/${item.date[1]}/${item.date[0]}` }}
                                </template>

                            </v-data-table>
                        </div>
                    </template>
                </stats-card>
                <!-- OVERALL SCATTER -->
                <stats-card v-else @titleActive="showOverall = true" title="Table" secondTitle="Scatter"  @expand="toggleOverallExpanded">
                    <template slot="headerActions">
                    <v-row>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                    :items="getTeamsHurlingSort"
                                    v-model="overallTeam"
                                    @change="updateOverallResults"
                                    label="Team"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                    :disabled="!overallTeam"
                                    :items="overallOppositionOption"
                                    v-model="overallOpposition"
                                    @change="updateOverallFilter"
                                    multiple
                                    label="Opposition"
                                    item-text="name" 
                                    return-object
                                    outlined
                                    hide-details
                                    hide-no-data
                                    style="text-overflow: ellipsis; white-space: nowrap;"
                                >
                                <template #selection="{ item }">
                                    <span v-if="overallOpposition.length === 1">{{ item.name }}</span>
                                    <span v-else-if="item.name == overallOpposition[0].name">{{ overallOpposition.length }} teams</span>
                                </template>
                                </v-combobox>
                            </div>
                        </v-col>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                            <div>
                                <v-combobox
                                    :items="overallHomeAwayOptions"
                                    v-model="overallHomeFilter"
                                    @change="overallHomeFilterChanged"
                                    item-text="label"
                                    return-object
                                    label="Venue"
                                    outlined
                                    hide-details
                                    hide-no-data
                                ></v-combobox>
                            </div>
                        </v-col>
                        <v-spacer/>
                        <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'" class="d-flex align-center">
                            <div>
                                <v-btn
                                    class="grey"
                                    :class="this.$vuetify.theme.dark ? 'darken-2' : 'lighten-2'"
                                    @click="showOverallFilters = !showOverallFilters"
                                    >
                                    {{ showOverallFilters ? 'Filters' : 'Filters' }}
                                    <v-icon class="ml-1">{{ showOverallFilters ? 'mdi-arrow-up-drop-circle-outline' : 'mdi-arrow-down-drop-circle-outline' }}</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <span v-if="overallTeam && overallOpposition.length==0" class="text-caption ml-2 danger--text">Scatter needs at least one opponent</span>
                    </v-row>
                    <transition name="fade">
                        <v-row v-if="showOverallFilters" :class="this.$vuetify.breakpoint.xs ? 'settings_height_mobile' : 'settings_height'">
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-combobox
                                        :items="overallYearFromFilters"
                                        v-model="overallYearFromFilter"
                                        @change="overallYearFromFilterChanged"
                                        label="Year from"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-combobox
                                        :items="overallYearToFilters"
                                        v-model="overallYearToFilter"
                                        @change="overallYearToFilterChanged"
                                        label="Year to"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-combobox
                                        :items="getOverallFilteredStadiums"
                                        @change="overallStadiumFilterChanged"
                                        v-model="overallStadiumFilter"
                                        item-text="label"
                                        item-value="value"
                                        label="Stadium"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>                            
                            <v-col :cols="this.$vuetify.breakpoint.smAndUp ? '3' : '12'">
                                <div>
                                    <v-combobox
                                        :items="overallStageOptions"
                                        @change="overallStageFilterChanged"
                                        v-model="overallStageFilter"
                                        item-text="label"
                                        item-value="value"
                                        label="Stage"
                                        outlined
                                        hide-details
                                        hide-no-data
                                    ></v-combobox>
                                </div>
                            </v-col>
                        </v-row>
                    </transition>
                </template>
                    <template slot="chart">
                        <div id="basicArea-chart" class="chartBackground">
                            <apexchart
                                type="scatter"
                                :options="overallScatterChartOptions"
                                :series="overallScatterSeries"
                            ></apexchart>
                        </div>
                    </template>
                </stats-card>
            </v-col>
        </v-row>
    </div>
  </template>
  
  <script>
  import StatsCard from '@/components/card/statsCard/StatsCard'
import { mapActions, mapGetters } from 'vuex';
  export default {
    name: 'HurlingTeamStats',
    components: {
        StatsCard
    },
    data() {
      return {
        loading: false,
        showAverage: true,
        showOverall: true,
        showHeadToHeadFilters: false,
        showOverallFilters: false,
        headToHeadExpanded: false,
        headToHeadHome: null,
        headToHeadAway: null,
        headToHeadFilter: {"label": 'All', "value": null},
        headToHeadYearFromFilter: 'All',
        headToHeadYearToFilter: 'All',
        headToHeadStadiumFilter: 'All',
        headToHeadStageFilter: 'All',
        overallExpanded: false,
        overallTeam: null,
        overallOpposition: [],
        overallYearFromFilter: 'All',
        overallYearToFilter: 'All',
        overallStadiumFilter: 'All',
        overallStageFilter: 'All',
        overallHomeFilter: null,
        overallTableHeaders: [
          {
            text: 'Opposition',
            sortable: false,
            value: 'opposition'
          },
          { text: 'Stadium', value: 'venue', sortable: false },
          { text: 'Score', value: 'result', sortable: false },
          { text: 'Date', value: 'date', sortable: false }
        ]
        }
    },
    computed: {
        ...mapGetters([
            "getTeamsHurlingSort", "getResults", "getResultHurlingTotals", "getFilteredHurlingResults", "getOverallResults", 
            "getFilteredOverallHurlingResults","getFilteredStadiums", "getOverallFilteredStadiums"
        ]),
        headToHeadPieChartOptions() { 
            return {
                title: {
                    text: 'Win Percentage',
                    align: 'left',
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#666'
                    }
                },
                subtitle: {
                    text: 'Know The Game',
                    align: 'left',
                    style: {
                        fontSize: '10px',
                        fontWeight: 'bold',
                        color: '#666'
                    }
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        color: '#000'
                    }
                },
                chart: {
                    type: 'pie',
                },
                fill: {
                    type: 'image',
                    opacity: 0.85,
                    image: {
                        src: this.headToHeadPieChartImages,
                        width: 400,
                        height: 400                    
                    },
                },
                export: {
                    enabled: true,
                    // Include download options in the menu
                    menu: {
                    download: ['png', 'svg']
                    }
                },
                colors: this.headToHeadPieChartColours,
                plotOptions: {
                    pie: {
                        expandOnClick: true,
                        startAngle: 0,
                    }
                },
                labels: this.headToHeadPieChartLabels,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            }
        },
        headToHeadHomeAwayOptions(){
            let teamOptions = [{"label": 'All', "value": null},{"label": 'Neutral', "value": -1}];
            if(this.headToHeadAway){
                teamOptions.push({"label": this.headToHeadAway.name, "value": this.headToHeadAway.id});
            }
            if(this.headToHeadHome){
                teamOptions.push({"label": this.headToHeadHome.name, "value": this.headToHeadHome.id});
            }
           return teamOptions;
        },
        headToHeadStageOptions(){
            let stages = ['All'];
            stages.push(...this.$store.getters['getResults'].map(it => it.stage));
            return stages;
        },
        headToHeadPieChartLabels(){
            let labels = ['Team 2', 'Team 1'];
            if(this.headToHeadHome){
                labels[0] = this.headToHeadHome.name + ' ' + this.headToHeadScatterSeries[0].data.length;
            }
            if(this.headToHeadAway){
                labels[1] = this.headToHeadAway.name + ' ' + this.headToHeadScatterSeries[1].data.length;
            }
            if(this.headToHeadHome && this.headToHeadAway){
                if(this.headToHeadScatterSeries[0].data.length>0 || this.headToHeadScatterSeries[1].data.length>0 || this.headToHeadScatterSeries[2].data.length>0){
                    labels.push('Draw' + ' ' + this.headToHeadScatterSeries[2].data.length);
                } else {
                    labels.push('Never met');
                }
            } else {
                labels.push('Never met');
            }
            
            return labels;
        },
        headToHeadPieChartColours(){
            let colours = ['#888888', '#949494', "#808080"];
            if(this.headToHeadHome){
                colours[0] = this.headToHeadHome.colour;
            }
            if(this.headToHeadAway){
                colours[1] = this.headToHeadAway.colour;
            }
            return colours;
        },
        headToHeadPieChartImages(){
            let images = ['', '', '/assets/gaa/teams/chart_icons/draw.jpg'];
            if(this.headToHeadHome){
                images[0] = `/assets/gaa/teams/icons/${this.headToHeadHome.code}.WEBP`;
            }
            if(this.headToHeadAway){
                images[1] =  `/assets/gaa/teams/icons/${this.headToHeadAway.code}.WEBP`;
            }
            return images;
        },
        headToHeadPieChartTotals(){
            let resultTotals = this.$store.getters['getResultHurlingTotals'];
            let charTotals = [0, 0, 1];
            if(this.headToHeadHome && !isNaN(resultTotals[this.headToHeadHome.id]) && this.headToHeadAway && !isNaN(resultTotals[this.headToHeadAway.id])){
               charTotals = [resultTotals[this.headToHeadHome.id], resultTotals[this.headToHeadAway.id], resultTotals['draw']]
            }
            
            return charTotals;
        },
        headToHeadPieChartTeamOption(){
            if(this.headToHeadAway){
                return this.$store.getters['getTeamsHurlingSort'].filter(it => it.id != this.headToHeadAway.id)
            }
            return this.$store.getters['getTeamsHurlingSort'];
        },
        headToHeadScatterChartOptions(){
            let colours = [];
            if(this.headToHeadHome && this.headToHeadAway){
                colours = [this.headToHeadHome.colour, this.headToHeadAway.colour, '#808080'];
            }
            return {
                title: {
                    text: 'Winning History',
                    align: 'left',
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#666'
                    }
                },
                chart: {
                    type: 'scatter',
                    zoom: {
                        enabled: false
                    }
                },
                colors: colours,
                markers: {
                    strokeWidth: 1,
                    strokeColor: '#000000'
                },
                xaxis: {
                    title: {
                        text: 'Year'
                    },
                    type: 'numeric',
                    tickAmount: 'dataPoints',
                    labels: {
                        formatter: function(val) {
                            let resp = val;
                            if(val && val.toFixed){
                                resp = val.toFixed(0)
                            }
                            return resp;
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: 'Winning Margin',
                        style: {
                            fontSize: '15px',
                            fontWeight: 'bold',
                            color: '#666'
                        }
                    },
                    min: 0
                }
            }
        },
        headToHeadScatterSeries(){
            let series = [];
            let team1 = { name: this.headToHeadAway ? this.headToHeadAway.name : 'Team 1', data: [] };
            let team2 = { name: this.headToHeadHome ? this.headToHeadHome.name : 'Team 2', data: [] };
            let draw = { name: "Draw", data: [] };
            for(let i=0; i<this.getFilteredHurlingResults.length; i++){
                let currentResult = this.getFilteredHurlingResults[i];
                if(currentResult.homeTeam.id == this.headToHeadHome.id){
                    if(currentResult.result == "HOME_WIN"){
                        team2.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints})
                    } else if(currentResult.result == "AWAY_WIN"){
                        team1.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints})
                    } else {
                        draw.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints});
                    }
                } else {
                    if(currentResult.result == "HOME_WIN"){
                        team1.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints})
                    } else if(currentResult.result == "AWAY_WIN"){
                        team2.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints})
                    } else {
                        draw.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints});
                    }
                }
            }
            series.push(team2);
            series.push(team1);
            series.push(draw);
            return series;
        },
        headToHeadYearToFilters(){
            let years = ['All'];
            years.push(...this.getResults.map(it => it.year));
            return years;
        },
        headToHeadYearFromFilters(){
            let years = ['All'];
            years.push(...this.getResults.map(it => it.year).sort((a, b) => a - b));
            return years;
        },
        overallOppositionOption(){
            let choices = this.$store.getters['getTeamsHurlingSort'].map(it => it);
            if(this.overallTeam){
                choices = choices.filter(it => it.id != this.overallTeam.id)
            }
            if(this.overallOpposition && this.overallOpposition.length > 0){
                choices = choices.filter(it => it.id != this.overallOpposition.id)
            }
            return choices;
        },
        overallHomeAwayOptions(){
            let teamOptions = [{"label": 'All', "value": null}, { "label": "Neutral", "value" : -1}];
            if(this.overallTeam){
                teamOptions.push({"label": this.overallTeam.name + ' home', "value": this.overallTeam.id});
                teamOptions.push({"label": this.overallTeam.name + ' away', "value": this.overallTeam.id*-1});
            }
           return teamOptions;
        },
        overallStageOptions(){
            let stages = ['All', 'Munster', 'Leinster', 'All Ireland'];
            return stages;
        },
        overallScatterChartOptions(){
            let colours = [];
            if(this.overallTeam){
                colours = [this.overallTeam.colour];
            }
            for(let i=0;i<this.overallOpposition.length;i++){
                colours.push(this.overallOpposition[i].colour);
            }
            colours.push('#808080');
            return {
                title: {
                    text: 'Winning History',
                    align: 'left',
                    style: {
                        fontSize: '20px',
                        fontWeight: 'bold',
                        color: '#666'
                    }
                },
                chart: {
                    type: 'scatter',
                    zoom: {
                        enabled: false
                    }
                },
                colors: colours,
                markers: {
                    strokeWidth: 1,
                    strokeColor: '#000000'
                },
                xaxis: {
                    title: {
                        text: 'Year'
                    },
                    type: 'numeric',
                    // tickAmount: this.calculateTicksForOverallScatter(),
                    labels: {
                        formatter: function(val) {
                            let resp = val;
                            if(val && val.toFixed){
                                resp = val.toFixed(0)
                            }
                            return resp;
                        }
                    }
                },
                yaxis: {
                    title: {
                        text: 'Winning Margin',
                        style: {
                            fontSize: '15px',
                            fontWeight: 'bold',
                            color: '#666'
                        }
                    },
                    min: 0
                },
                dataLabels: {
                    enabled: false,
                    formatter: function(value, { seriesIndex, dataPointIndex, w }) {
                        // Custom formatting for data point labels
                        return `Custom Label ${seriesIndex + 1}-${dataPointIndex + 1}: ${value}`;
                    }
                }
            }
        },
        overallScatterSeries(){
            let series = [];
            let team1 = { name: this.overallTeam ? this.overallTeam.name : 'Team', data: [] };
            let draw = { name: "Draw", data: [] };
            series.push(team1);
            for(let i=0; i<this.overallOpposition.length; i++){
                let opposingTeam = { name: this.overallOpposition[i].name, data: [] };
                for(let i=0; i<this.getFilteredOverallHurlingResults.length; i++){
                    let currentResult = this.getFilteredOverallHurlingResults[i];
                    if(currentResult.homeTeam.id == this.overallTeam.id){
                        if(currentResult.result == "HOME_WIN"){
                            if(opposingTeam.name == currentResult.awayTeam.name){
                                team1.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints})
                            }
                        } else if(currentResult.result == "AWAY_WIN"){
                            if(opposingTeam.name == currentResult.awayTeam.name){
                                opposingTeam.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints})
                            }
                        } else {
                            if(opposingTeam.name == currentResult.awayTeam.name){
                                draw.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints});
                            }
                        }
                    } else if(currentResult.awayTeam.id == this.overallTeam.id){
                        if(currentResult.result == "HOME_WIN"){
                            if(opposingTeam.name == currentResult.homeTeam.name){
                                opposingTeam.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints})
                            }
                        } else if(currentResult.result == "AWAY_WIN"){
                            if(opposingTeam.name == currentResult.homeTeam.name){
                                team1.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints})
                            }
                        } else {
                            if(opposingTeam.name == currentResult.homeTeam.name){
                                draw.data.push({"x": currentResult.year, "y": currentResult.winningMarginPoints});
                            }
                        }
                    }
                }
                series.push(opposingTeam);
            }
            series.push(draw);
            return series;
        },
        overallYearFromFilters(){
            let years = ['All'];
            if(this.getFilteredOverallHurlingResults){
                years.push(...this.getFilteredOverallHurlingResults.map(it => it.year).sort( (a,b) => a-b ));
            }
            return years;
        },
        overallYearToFilters(){
            let years = ['All'];
            if(this.getFilteredOverallHurlingResults){
                years.push(...this.getFilteredOverallHurlingResults.map(it => it.year));
            }
            return years;
        }
    },
    mounted(){
        this.fetchTeamsBySport("HURLING");
    },
    methods: {
        ...mapActions([
            "fetchTeamsBySport", "fetchHeadToHeadResults", "updateHeadToHeadHomeFilter", 
            "updateHeadToHeadYearFromFilter", "updateHeadToHeadYearToFilter", "updateOverallYearFromFilter", 
            "updateOverallYearToFilter", "fetchOverallResults", 'updateOverallOppositionFilter', 'updateOveralldHomeFilter',
            "updateHeadToHeadStadiumFilter", "updateHeadToHeadStageFilter", "updateOverallStadiumFilter", "updateOverallStageFilter"
        ]),
        toggleGameweekExpanded(){
            this.loading = true;
            setTimeout(()=>{this.loading = false}, 500);
        },
        toggleHeadToHeadExpanded(){
            this.loading = true;
            this.headToHeadExpanded = !this.headToHeadExpanded;
            setTimeout(()=>{this.loading = false}, 500);
        },
        toggleOverallExpanded(){
            this.loading = true;
            this.overallExpanded = !this.overallExpanded;
            setTimeout(()=>{this.loading = false}, 500);
        },
        updateHeadToHeadResults(){
            if(this.headToHeadHome && this.headToHeadAway){
                this.fetchHeadToHeadResults({"team1Id": this.headToHeadHome.id, "team2Id": this.headToHeadAway.id, "competitionId": 2});
            }
            if(!((this.headToHeadHome && this.headToHeadFilter.value == this.headToHeadHome.id) || (this.headToHeadAway && this.headToHeadFilter.value == this.headToHeadAway.id))){
                this.headToHeadFilter = {"label": 'All', "value": null};
                this.updateHeadToHeadFilter();
            }
        },
        updateHeadToHeadFilter(){
            this.updateHeadToHeadHomeFilter(this.headToHeadFilter)
        },
        headToHeadStadiumFilterChanged(){
            this.updateHeadToHeadStadiumFilter(this.headToHeadStadiumFilter);
        },
        headToHeadStageFilterChanged(){
            this.updateHeadToHeadStageFilter(this.headToHeadStageFilter);
        },
        headToHeadYearFromFilterChanged(){
            this.updateHeadToHeadYearFromFilter(this.headToHeadYearFromFilter);
        },
        headToHeadYearToFilterChanged(){
            this.updateHeadToHeadYearToFilter(this.headToHeadYearToFilter);
        },
        updateOverallResults(){
            if(this.overallTeam){
                this.fetchOverallResults({"teamId": this.overallTeam.id, "competitionId": 2});
            }
        },
        updateOverallFilter(){
            this.updateOverallOppositionFilter(this.overallOpposition);
        },
        overallHomeFilterChanged(){
            this.updateOveralldHomeFilter(this.overallHomeFilter);
        },
        overallYearFromFilterChanged(){
            this.updateOverallYearFromFilter(this.overallYearFromFilter);
        },
        overallYearToFilterChanged(){
            this.updateOverallYearToFilter(this.overallYearToFilter);
        },
        overallStadiumFilterChanged(){
            this.updateOverallStadiumFilter(this.overallStadiumFilter);
        },
        overallStageFilterChanged(){
            this.updateOverallStageFilter(this.overallStageFilter);
        },
        calculateResultText(item){
            if(item.homeTeam && item.homeTeam.id == this.overallTeam.id){
                return `${item.homeGoals}-${item.homePoints} ${item.awayGoals}-${item.awayPoints}`
            } else {
                return `${item.awayGoals}-${item.awayPoints} ${item.homeGoals}-${item.homePoints}`
            }
        },
        calculateResultColour(item){
            if(item.homeTeam && item.homeTeam.id == this.overallTeam.id){
                if(item.result == 'HOME_WIN'){
                    return 'color: green';
                }else if(item.result == 'AWAY_WIN'){
                    return 'color: red';
                }else {
                    return '';
                }
            } else {
                if(item.result == 'HOME_WIN'){
                    return 'color: red';
                }else if(item.result == 'AWAY_WIN'){
                    return 'color: green';
                }else {
                    return '';
                }
            }
        },
        calculateTicksForOverallScatter(){
            return 10;
        }
    }
  }
</script>  

<style scoped>
  .settings_height {
    max-height: 200px;
    overflow: hidden;
    }

    .settings_height_mobile {
    max-height: 240px;
    overflow: hidden;
    }

    .fade-enter-active, .fade-leave-active {
    transition: max-height 0.5s  ease-in;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    max-height: 0;
    }
    .chartBackground {
        background-image: url('/assets/logo/ktg_green_fade.svg');
        background-size: contain;
        background-position: center;
    }
    .img_border {
        border-style: solid;
        border-width: 1px;
    }
</style>